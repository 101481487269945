import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";
import GlobalStyle from "../../utils/GlobalStyle";

// VIEWS
//'import Offers from "./Offers";
import AppBar from "./AppBar";
import Demos from "./Demos";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import Introduction from "./Introduction";
import Pricing from "./Pricing";
import Contact from "./Contact";
import Brand from "./Brand";
import News from "./News";
import Institution from "./Institution";
//import Examples from './Examples';

// Styled components
const Root = styled.div`
  min-height: 100vh;
  min-width: 100%;
  background: #fff;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
`;

/*
On this page the rest of the views are centralized, it places them one after another
*/

const Presentation = () => {
  return (
    <Root>
      {/* 
            these components provide the theme with material design styles
            is similar to calling the file directly
            <link src = "assets/css/globalstyle.css">
            */}
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        {/* <Brand />*/}
        <AppBar timeOut={0} />
        <Introduction />
        <Demos />
        <AboutUs />
        <News />
        <Institution />
        <Pricing />
        <Contact />
        <Footer />
        <Outlet />
      </AppContent>
    </Root>
  );
};

export default Presentation;
