import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";

import {
  Grid,
  Typography as MuiTypography,
  Container,
  Button as MuiButton,
} from "@mui/material";

// Styled components
const Typography = styled(MuiTypography)(spacing);

const Title = styled(Typography)`
  line-height: 1.2;
  font-size: 1.35rem;
  text-align: center;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => props.theme.breakpoints.up("xs")} {
    font-size: 2rem;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 3rem;
  }
  span {
    color: #000;
  }
`;

const Button = styled(MuiButton)`
  width: 300px;
  font-size: 1.2rem;
  font-weight: 4rem;
  padding: 10px;
  border-radius: 10px;
  margin-top: 40px;
`;

const SorryInit = () => {
  return (
    <Container align="center">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        height={600}
      >
        <Grid item>
          <Title variant="h3" gutterBottom>
            En este momento no estamos aceptando nuevos registros. Para
            comunicarte con nosotros escribe un correo a
            <a>j@guatedatasuite.com</a>
          </Title>
          <Button
            ml={2}
            color="primary"
            variant="contained"
            component={Link}
            to="/"
          >
            Regresar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SorryInit;
