import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, NavLink } from "react-router-dom";

// Personal Hooks
import useAuth from "../../hooks/useAuth";
import useNotify from "../../hooks/useNotify";

// import phone-number library and css
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

// MUI Components
import {
  Grid,
  Paper,
  Box,
  Typography,
  Link,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

//Icons
import ReceiptIcon from "@mui/icons-material/Receipt";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import logo from "../../vendor/favicon.svg";

// Template components FormikForm
import { Field } from "formik";
import {
  FormikForm,
  CustomInputComponent,
  PasswordInputComponent,
} from "../../components/Form/Formik/FormikForm";

// validations schemas
import * as Yup from "yup";
import {
  emailSchema,
  passwordAndConfirmSchema,
} from "../../utils/validationSchemas";

// Dialogs
import DialogPlans from "../plans/DialogPlans";

//plans
import { plans_all } from "../../utils/plans.codes";

// Styled Components
import styled from "styled-components/macro";

const InputTelStyleWrapper = styled.div`
  margin: 10px 0 10px 0;
  .PhoneInput {
    input {
      width: 100%;
      height: 52px;
      font-size: 0.9rem;
      font-family: ${(props) => props.theme.typography.fontFamily};
      outline: none;
      border-radius: 4px;
      border: 1px solid #cccccc;
      padding: 0 10px;
    }
    input:hover {
      border: 1px solid #000;
    }
    input:focus {
      border: 2px solid ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(8)};
`;

const Brand = styled(Paper)`
  color: #5271ff;
  background-color: rgba(0, 0, 0, 0);
  font-size: ${(props) => props.theme.typography.h3.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  cursor: pointer;
  height: 120px;
  width: 370px;
  text-decoration: none;
`;

const BrandIcon = styled.div`
  width: 60px;
  height: 60px;
`;

const ImgLogo = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 2px;
`;

// Default values ​​of the form, these values ​​are not valid and are not sent until the required fields are filled.
const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number_sms: "",
  password: "",
  confirm_password: "",
  companyName: "",
  nit: "",
  plan: localStorage.getItem("plan") || "BA",
  payment_method: "TRANSFERENCIA",
};

/*
    Object to validate the entry of the elements, 
    that are required, that meet requirements such as size, the passwords must match, etc.
*/
const validationSchema = Yup.object().shape({
  first_name: Yup.string().max(255).required("El nombre es requerido"),
  last_name: Yup.string().max(255).required("El apellido es requerido"),
  companyName: Yup.string().max(255),
  nit: Yup.string().max(255),
  email: emailSchema,
  ...passwordAndConfirmSchema,
});

const payment_method_buttons = [
  { value: "TRANSFERENCIA", name: "Transferencia", logo: <ReceiptIcon /> },
  { value: "STRIPE", name: "Tarjeta Credito/Debito", logo: <CreditCardIcon /> },
];

const Banner = () => {
  return (
    <Brand component={NavLink} to="/" className="fontFamily">
      <Grid container alignItems="center" spacing={12}>
        <Grid item md={2} xs={2} sm={2}>
          <BrandIcon style={{ backgroundImage: `url(${logo})` }}></BrandIcon>
        </Grid>
        <Grid item md>
          GUATE DATA SUITE
        </Grid>
      </Grid>
    </Brand>
  );
};

const PlanCard = ({ plan, setPlan }) => {
  const [openPlansView, setOpenPlansView] = useState(false);

  return (
    <Box sx={{ pb: 2, width: "100%" }}>
      <Grid container>
        <Grid item xs>
          <Typography variant="h4" align="left" display="block">
            Plan Actual
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography align="right">
            <Link
              component="button"
              color={"#5271ff"}
              underline="always"
              onClick={() => {
                setOpenPlansView(true);
              }}
            >
              Cambiar el plan
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <Wrapper style={{ background: plan.background }}>
        <Grid container>
          <Grid item xs>
            <Typography variant="h6" align="left" color={plan.color}>
              {plan.name}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" align="right" color={plan.color}>
              {plan.price} / {plan.month}
            </Typography>
          </Grid>
        </Grid>
      </Wrapper>
      {openPlansView && (
        <DialogPlans
          open={openPlansView}
          setOpen={setOpenPlansView}
          setPlan={setPlan}
          basicPlan={true}
        />
      )}
    </Box>
  );
};

function SignUpPage() {
  //use this hook to move between views
  /*
        use the signIn function of the custom hook, 
        this function receives the parameters to create the account and sends it to the api
    */

  const { signUp } = useAuth();
  const { notifyShow } = useNotify();
  const navigate = useNavigate();

  /*This property is used to preview the photo selected to create the account */
  const [preview, setPreview] = useState();

  // user properties that cannot be placed in the schema
  const [phoneNumber, setPhoneNumber] = useState();

  const [plan, setPlan] = useState(localStorage.getItem("plan") || "BA");

  const [plan_act, setPlanAct] = useState(
    plans_all.filter((item) => item.plan === plan)
  );

  const [paymentMethod, setPaymentMethod] = React.useState("STRIPE");

  useEffect(() => {
    const sorryReturn = () => {
      navigate("/sorry");
    };
    sorryReturn();
  }, []);

  const handleChange = (event, newPaymentMethod) => {
    setPaymentMethod(newPaymentMethod);
  };

  const changePlan = (plan_update) => {
    const plan_actFind = plans_all.filter((item) => item.plan === plan_update);
    setPlanAct(plan_actFind);
    setPlan(plan_update);
  };

  //This function sends the data to the api to create the account

  const signUpFunction = async (values) => {
    if (phoneNumber && isPossiblePhoneNumber(phoneNumber) ? false : true) {
      notifyShow("Número telefónico erróneo", "error", "filled", [
        "button",
        "center",
      ]);
      return;
    }

    values.photo_url = preview;
    values.phone_number_sms = phoneNumber;
    values.plan = plan_act[0].plan;

    if (values.plan !== "BA" || values.plan !== "BAA") {
      values.payment_method = paymentMethod;
      localStorage.setItem(
        "recurring_data",
        JSON.stringify({
          selected_plan: values.plan,
          name_user: values.first_name,
        })
      );
    }

    delete values.confirm_password;

    const response = await signUp(values);
    notifyShow(response.message, "success", "filled", ["button", "center"]);
    navigate("/auth/sign-in");
  };
  return (
    <Fragment>
      <Helmet title="Sign Up" />
      <Banner />
      <PlanCard plan={plan_act[0]} setPlan={changePlan} />

      <Wrapper>
        <Typography component="h1" variant="h4" align="left" gutterBottom>
          ¡Bienvenido!
        </Typography>
        <Typography
          color="text.secondary"
          component="h2"
          variant="caption"
          align="left"
        >
          Vamos a configurarlo todo para que pueda verificar su cuenta personal.
        </Typography>
        <br />

        {/*  Initialize the form with the initial values ​​and the validation scheme */}

        <FormikForm
          initialValues={initialValues}
          validationSchema={validationSchema}
          functionToExecute={signUpFunction}
          titleButton="Crear cuenta"
          buttonProps={{ fullWidth: true }}
        >
          {/* Photo section 
           <label htmlFor="file-input">
            <BigAvatar alt="Mi foto" src={preview} />
          </label>

          <input
            id="file-input"
            onChange={
              // this function is used to upload the profile picture 
              (e) => {
                const fileReader = new FileReader();
                fileReader.onload = () => {
                  if (fileReader.readyState === 2) {
                    setPreview(fileReader.result);
                  }
                };
                fileReader.readAsDataURL(e.target.files[0]);
              }
            }
            hidden
            name="url_photo"
            type="file"
            accept="image/*"
          />
          */}

          {/** section data */}

          <Grid container spacing={2}>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <Field
                type="text"
                name="first_name"
                label="Nombre*"
                as={CustomInputComponent}
              />
            </Grid>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <Field
                type="text"
                name="last_name"
                label="Apellido*"
                as={CustomInputComponent}
              />
            </Grid>
          </Grid>

          <Field
            type="email"
            name="email"
            label="Correo electrónico*"
            as={CustomInputComponent}
          />
          <Grid container spacing={2}>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <Field
                type="text"
                name="companyName"
                label="Nombre de compañía (opcional)"
                as={CustomInputComponent}
              />
            </Grid>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <Field
                type="text"
                name="nit"
                label="NIT (opcional)"
                as={CustomInputComponent}
              />
            </Grid>
          </Grid>

          <InputTelStyleWrapper>
            <PhoneInput
              placeholder="Número de teléfono*"
              defaultCountry="GT"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </InputTelStyleWrapper>

          <Grid container spacing={2}>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <Field
                name="password"
                label="Contraseña"
                as={PasswordInputComponent}
              />
            </Grid>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <Field
                name="confirm_password"
                label="Confirmar Contraseña*"
                as={PasswordInputComponent}
              />
            </Grid>
          </Grid>
          {!(plan === "BA" || plan === "BAA") && (
            <Box sx={{ pb: 3 }}>
              <Typography variant="caption" color="text.secondary">
                Forma de pago:
              </Typography>
              <ToggleButtonGroup
                exclusive
                fullWidth
                color="info"
                value={paymentMethod}
                onChange={handleChange}
              >
                {payment_method_buttons.map((button) => (
                  <ToggleButton value={button.value}>
                    {button.logo}
                    {button.name}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>

              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontStyle: "italic" }}
              >
                {paymentMethod === "TRANSFERENCIA" &&
                  "Se le notificara a un miembro de nuestro equipo para poder completar el pago (puede tardar cierto tiempo)"}
                {paymentMethod === "STRIPE" &&
                  "Pago con tarjeta de crédito de manera fácil y rápida (mejor opción)"}
              </Typography>
            </Box>
          )}
        </FormikForm>
        <Link key="register" color="#5271ff" href="/auth/sign-in">
          ¿Ya tienes una cuenta? <b>Inicia sesión aquí</b>
        </Link>
        <br />
        <Link
          key="support"
          sx={{ color: "#5271ff" }}
          href="/support"
          target="_blank"
        >
          ¿Encontraste problemas técnicos?
          <Link style={{ fontWeight: "bold", color: "#5271ff" }}>
            {" "}
            Escríbenos y te ayudaremos lo más pronto posible.
          </Link>{" "}
        </Link>
      </Wrapper>
    </Fragment>
  );
}

export default SignUpPage;
