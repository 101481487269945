import React from "react";
import async from "./components/Loader/Async";
// PROVIDERS
import { FilterProvider } from "./contexts/FiltersContext";

// Presentation
import PresentationLayout from "./pages/presentation/Presentation";

// Dashboard
import DashboardLayout from "./pages/dashboard/DashboardLayout";

// Pricing Page
import PricingDetails from "./pages/presentation/PricingDetails";
// Terms and dondition page
import TermsAndConditions from "./pages/presentation/TermsAndConditions";

//Auth pages
import Auth from "./pages/auth/Auth";
import SignInPage from "./pages/auth/SignInPage";
import SignUpPage from "./pages/auth/SignUpPage";
import ResetPassword from "./pages/auth/ResetPassword";
import PlanAdded from "./pages/plans/Plan_added";
import PlanUpdate from "./pages/plans/Plan_update";
import NotFound from "./pages/error404/NotFound";
import CreateBlog from "./pages/blog/CreateBlog";
import Post from "./pages/blog/Post";
import Posts from "./pages/blog/Posts";
import HelpAuth from "./pages/auth/HelpAuth";

//help pages
import HelpPage from "./pages/documentation/Help.Page";
import HelpVideo from "./pages/documentation/Help.Video";
import HelpDictionary from "./pages/documentation/Help.Dictionary";
import HelpQuestions from "./pages/documentation/Help.Questions";
import Sorry from "./pages/error404/Sorry";
//PAGINA PRINCIPAL
const Home = async(() => import("./pages/home/Home"));
//EVENTOS VIGENTES
const BusquedaVigentes = async(() =>
  import("./pages/vigentes/BusquedaVigente")
);
//EVENTOS ADJUDICADOS
const BusquedaAdjudicado = async(() =>
  import("./pages/adjudicado/BusquedaAdjudicado")
);
const AnalyticsRowsFiltered = async(() =>
  import("./pages/adjudicado/analytics/AnalyticsRowsFiltered")
);
//DETALLE DEL EVENTO
const DetailEvent = async(() => import("./pages/single-event/DetailEvent"));
//CALENDARIO
const Calendar = async(() => import("./pages/calendar/Calendar"));
//PROFILE
const Profile = async(() => import("./pages/profile/Profile"));
//FOLLOWERS
const Followers = async(() => import("./pages/follower/Followers"));
//SUBSCRIPTIONS
const Subscription = async(() => import("./pages/subscription/Subscriptions"));
//PORTAFOLIOS
const Portafolios = async(() => import("./pages/portafolio/Portafolios"));
//PREDICTIONS
const Predictions = async(() => import("./pages/portafolio-stats/Predictions"));
//ControlPanel administration
const ControlPanel = async(() => import("./pages/administration/ControlPanel"));
//ControlUsers administration
const ControlUsers = async(() => import("./pages/administration/ControlUsers"));

const SearchPrice = async(() =>
  import("./pages/search-price/SearchPrice.view")
);

const routes = [
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "sorry",
    element: <Sorry />,
  },
  {
    path: "/",
    element: <PresentationLayout />,
  },
  {
    path: "pricing-details",
    element: <PricingDetails />,
  },
  {
    path: "approved_payment",
    element: <PlanAdded />,
  },
  {
    path: "terms",
    element: <TermsAndConditions />,
  },
  {
    path: "support",
    element: <HelpAuth />,
  },
  {
    path: "auth",
    element: <Auth />,
    children: [
      {
        path: "sign-in",
        element: <SignInPage />,
      },
      {
        path: "sign-up",
        element: <SignUpPage />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "pricing-details",
        element: <PlanUpdate />,
      },
      {
        path: "analysis-results",
        element: <AnalyticsRowsFiltered />,
      },
      {
        path: "price-search",
        element: (
          <FilterProvider>
            <SearchPrice />
          </FilterProvider>
        ),
      },
      {
        path: "current-search",
        element: (
          <FilterProvider>
            <BusquedaVigentes />
          </FilterProvider>
        ),
      },
      {
        path: "historical-search",
        element: (
          <FilterProvider>
            <BusquedaAdjudicado />
          </FilterProvider>
        ),
      },
      {
        path: "event/:nog",
        element: <DetailEvent />,
      },
      {
        path: "calendar",
        element: <Calendar />,
      },
      {
        path: "followers",
        element: <Followers />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "subscriptions",
        element: <Subscription />,
      },
      {
        path: "portfolios",
        element: <Portafolios />,
      },
      {
        path: "predictions",
        element: <Predictions />,
      },
      {
        path: "help",
        element: <HelpPage />,
      },
      {
        path: "blog",
        element: <Posts />,
      },
      {
        path: "blog/:posturl",
        element: <Post />,
      },
      {
        path: "blog/create",
        element: <CreateBlog />,
      },
      {
        path: "docs/dictionary",
        element: <HelpDictionary />,
      },
      {
        path: "docs/questions",
        element: <HelpQuestions />,
      },
      {
        path: "docs/video/:video",
        element: <HelpVideo />,
      },
    ],
  },
  {
    path: "administration",
    element: <DashboardLayout />,
    children: [
      {
        path: "control-panel",
        element: <ControlPanel />,
      },
      /*
        {
          path: "control-users",
          element: <ControlUsers />
        },
       */
    ],
  },
];

export default routes;
