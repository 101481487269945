import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";
import GlobalStyle from "../../utils/GlobalStyle";

// VIEWS
import AppBar from "../presentation/AppBar";
import Footer from "../presentation/Footer";
import SorryInit from "./SorryInit";
//import Examples from './Examples';

// Styled components
const Root = styled.div`
  min-height: 100vh;
  min-width: 100%;
  background: #fff;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
`;

/*
On this page the rest of the views are centralized, it places them one after another
*/

const Sorry = () => {
  return (
    <Root>
      {/* 
            these components provide the theme with material design styles
            is similar to calling the file directly
            <link src = "assets/css/globalstyle.css">
            */}
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <AppBar />
        <SorryInit />
        <Footer />
        <Outlet />
      </AppContent>
    </Root>
  );
};

export default Sorry;
